import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: () => import('@/views/login/Index'),
    },
    {
      path: '/terms',
      component: () => import('@/views/terms/Index'),
    },
    {
      path: '/home',
      component: () => import('@/views/dashboard/Index'),
      redirect: '/home/dashboard',
      children: [
        // Dashboard
        {
          name: 'dashboard',
          path: '/home/dashboard',
          component: () => import('@/views/dashboard/Dashboard'),
        },
        // Pages
        {
          name: '',
          path: '/home/empty',
          component: () => import('@/views/dashboard/pages/Empty'),
        },
        {
          name: 'clients',
          path: '/home/clients',
          component: () => import('@/views/dashboard/pages/Clients'),
        },
        {
          name: 'client',
          path: '/home/client',
          component: () => import('@/views/dashboard/pages/Client'),
        },
        {
          name: 'training_plans_base',
          path: '/home/trainingPlans',
          component: () => import('@/views/dashboard/pages/TrainingPlans'),
        },
        {
          name: 'pdf_training_plans_base',
          path: '/home/pdftrainingPlans',
          component: () => import('@/views/dashboard/pages/PdfTrainingPlans'),
        },
        {
          name: 'plans_base',
          path: '/home/plans',
          component: () => import('@/views/dashboard/pages/Plans'),
        },
        {
          name: 'trains',
          path: '/home/trains',
          component: () => import('@/views/dashboard/pages/Trains'),
        },
        {
          name: 'exercises',
          path: '/home/workouts',
          component: () => import('@/views/dashboard/pages/Workouts'),
        },
        {
          name: 'classes_exercises',
          path: '/home/workouts',
          component: () => import('@/views/dashboard/pages/Workouts'),
        },
        {
          name: 'nutrition_base',
          path: '/home/nutritionBase',
          component: () => import('@/views/dashboard/pages/NutritionBase'),
        },
        {
          name: 'employees',
          path: '/home/employees',
          component: () => import('@/views/dashboard/pages/Employees'),
        },
        {
          name: 'payments_packs',
          path: '/home/paymentsPacks',
          component: () => import('@/views/dashboard/pages/PaymentsPacks'),
        },
        {
          name: 'promotional_codes',
          path: '/home/promotionalCodes',
          component: () => import('@/views/dashboard/pages/PromotionalCodes'),
        },
        {
          name: 'partnerships',
          path: '/home/partnerships',
          component: () => import('@/views/dashboard/pages/Partnerships'),
        },
        {
          name: 'partnership',
          path: '/home/partnership',
          component: () => import('@/views/dashboard/pages/Partnership'),
        },
        {
          name: 'contents',
          path: '/home/contents',
          component: () => import('@/views/dashboard/pages/Contents'),
        },
        {
          name: 'supplements',
          path: '/home/supplements',
          component: () => import('@/views/dashboard/pages/Supplements'),
        },
        {
          name: 'streaming',
          path: '/home/streaming',
          component: () => import('@/views/dashboard/pages/Streaming'),
        },
        {
          name: 'notifications',
          path: '/home/notifications',
          component: () => import('@/views/dashboard/pages/Notifications'),
        },
        {
          name: 'lives',
          path: '/home/lives',
          component: () => import('@/views/dashboard/pages/Lives'),
        },
        {
          name: 'recipes',
          path: '/home/recipes',
          component: () => import('@/views/dashboard/pages/Recipes'),
        },
        {
          name: 'recipe',
          path: '/home/recipe',
          component: () => import('@/views/dashboard/pages/Recipe'),
        },
        {
          name: 'foods',
          path: '/home/foods',
          component: () => import('@/views/dashboard/pages/Foods'),
        },
        {
          name: 'nutrition_plans_base',
          path: '/home/foodPlansBase',
          component: () => import('@/views/dashboard/pages/FoodPlansBase'),
        },
        {
          name: 'diets_base',
          path: '/home/dietsBase',
          component: () => import('@/views/dashboard/pages/DietsBase'),
        },
        {
          name: 'user_profile',
          path: '/home/userProfile',
          component: () => import('@/views/dashboard/pages/UserProfile'),
        },
        {
          name: 'payments',
          path: '/home/ptPaymentsHistory',
          component: () => import('@/views/dashboard/pages/PtPaymentsHistory'),
        },
        {
          name: 'settings',
          path: '/home/settings',
          component: () => import('@/views/dashboard/pages/Settings'),
        },
        {
          name: 'support',
          path: '/home/support',
          component: () => import('@/views/dashboard/pages/Support'),
        },
        {
          name: 'ruffle',
          path: '/home/ruffle',
          component: () => import('@/views/dashboard/pages/Ruffle'),
        },
        {
          name: 'training_plan',
          path: '/home/trainingPlan',
          component: () => import('@/views/dashboard/pages/TrainingPlan'),
        },
        {
          name: 'nutrition_plan',
          path: '/home/foodPlan',
          component: () => import('@/views/dashboard/pages/FoodPlan'),
        },
        {
          name: 'physical_evaluation',
          path: '/home/physicalEvaluation',
          component: () => import('@/views/dashboard/pages/PhysicalEvaluation'),
        },
        {
          name: 'payment',
          path: '/home/payment',
          component: () => import('@/views/dashboard/pages/Payment'),
        },
        {
          name: 'select_exercise_view_progress',
          path: '/home/trainingPlanProgress',
          component: () => import('@/views/dashboard/pages/TrainingPlanProgress'),
        },
        {
          name: 'progress',
          path: '/home/trainingPlanWeeksProgress',
          component: () => import('@/views/dashboard/pages/TrainingPlanWeeksProgress'),
        },
        {
          name: 'statistics',
          path: '/home/statistics',
          component: () => import('@/views/dashboard/pages/Statistics'),
        },
        {
          name: 'calendar',
          path: '/home/calendar',
          component: () => import('@/views/dashboard/pages/Calendar'),
        },
        {
          name: 'bookings',
          path: '/home/bookings',
          component: () => import('@/views/dashboard/pages/Bookings'),
        },
        {
          name: 'mkt_clint',
          path: '/home/mktClint',
          component: () => import('@/views/dashboard/pages/MktClint'),
        },
        {
          name: 'scouting',
          path: '/home/scouting',
          component: () => import('@/views/dashboard/pages/Scouting'),
        },
        {
          name: 'tasks',
          path: '/home/tasks',
          component: () => import('@/views/dashboard/pages/Tasks'),
        },
        {
          name: 'tutorials',
          path: '/home/tutorials',
          component: () => import('@/views/dashboard/pages/Tutorials'),
        },
      ],
    },
  ],
})

router.previousStates = []
router.navigationAction = ''

router.beforeEach((to, from, next) => {
  if (router.navigationAction === 'replace' || router.navigationAction === '') {
    if (router.previousStates.length) {
      router.previousStates[router.previousStates.length - 1] = from.path
    }
  }

  if (router.navigationAction === 'push') {
    const index = router.previousStates.indexOf(to.path)
    if (index > -1) {
      router.previousStates = router.previousStates.slice(0, index + 1)
    } else {
      if (router.previousStates[router.previousStates.length - 1] !== from.path) {
        router.previousStates.push(from.path)
      }
    }
  }

  router.navigationAction = ''
  next()
})

router.goBack = (page) => {
  if (router.previousStates.length) {
    const index = router.previousStates.length - 1
    const previousPath = router.previousStates[index]
    router.previousStates.splice(index, 1)
    router.navigationAction = 'back'
    router.replace(page && previousPath !== page ? page : previousPath)
  } else {
    router.back()
  }
}

router.navigate = {
  push: (data) => {
    router.navigationAction = 'push'
    router.push(data)
  },
  replace: (data) => {
    router.navigationAction = 'replace'
    router.replace(data)
  }
}

export default router